import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv } from '../../animations/components';
import { animationContainer, cardUp } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import { ITextCardItem, TextCard } from '../../components/TextCard';
import useContentAnimation from '../../hooks/use-content-animation';

import { ScBenefitsItemsWrapper, ScBenefitsWrapper } from './styled';

const items: ITextCardItem[] = [
	{
		smallTitle: 'On your side',
		largeTitle: 'On your<br/>side',
		text: 'Our retailer commission is just 3% (same as a credit card) because we believe the cycle to work scheme needs to work for everyone, including the bike shops that support it.',
	},
	{
		smallTitle: 'Backed by the industry',
		largeTitle: 'Backed by the<br/>industry',
		text: 'We’re the only cycle to work scheme that is endorsed by the Association of Cycle Traders, thanks to our fair and transparent pricing.',
	},
	{
		smallTitle: 'Get paid quickly',
		largeTitle: 'Get paid<br/>quickly',
		text: 'No more waiting days to get paid once you have redeemed a cycle to work voucher. We pay you straight away, no excuses.',
	},
];

export const Benefits = () => {
	const { ref, controls } = useContentAnimation();
	const theme = useTheme();

	return (
		<ScContainer>
			<motion.section initial="hidden" animate={controls} variants={animationContainer}>
				<ScBenefitsWrapper>
					<ScBenefitsItemsWrapper ref={ref}>
						{items.map((item) => (
							<MotionDiv key={item.smallTitle} variants={cardUp}>
								<TextCard
									item={item}
									breakpoint={1220}
									background={theme.colors.toasted}
									color={theme.colors.strongWhite}
								/>
							</MotionDiv>
						))}
					</ScBenefitsItemsWrapper>
				</ScBenefitsWrapper>
			</motion.section>
		</ScContainer>
	);
};
