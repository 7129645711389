import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv, MotionImage } from '../../animations/components';
import { animationContainer, fadeInLeft, fadeInRight, fadeInUp } from '../../animations/variants';
import image from '../../assets/images/promise-1.png';
import { ScContainer } from '../../components/container/styled';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { useMatchMedia } from '../../hooks/use-match-media';
import { ScReadyToStartSavingTextWrapper } from '../Home/styled';

import { ScFlexiVoucherWrapper, ScTitleWrapper } from './styled';

export const FlexiVoucherInfo = () => {
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section initial="hidden" animate={controls} variants={animationContainer}>
			<ScContainer>
				<ScFlexiVoucherWrapper>
					<MotionImage
						variants={isDesktop ? fadeInLeft : fadeInUp}
						src={image}
						alt="ready to start saving image"
					/>
					<ScReadyToStartSavingTextWrapper ref={ref}>
						<MotionDiv variants={fadeInRight}>
							<ScTitleWrapper>
								<ScTitle isMobileLarge color={theme.colors.darkText} centered={!isDesktop}>
									Why the flexi-voucher is
									{isDesktop && <br />} worth shouting about
								</ScTitle>
							</ScTitleWrapper>
						</MotionDiv>
						<MotionDiv variants={fadeInRight}>
							<ScParagraph color={theme.colors.darkText} centered={!isDesktop} className="text">
								Unlike other cycle schemes, our flexi-voucher allows customers to spend wherever,
								whenever they want. There’s no limitation on how many times the voucher can be used
								within a year which means more control for them, and more revenue for you.
							</ScParagraph>
						</MotionDiv>
					</ScReadyToStartSavingTextWrapper>
				</ScFlexiVoucherWrapper>
			</ScContainer>
		</motion.section>
	);
};
