import { motion } from 'framer-motion';
import styled from 'styled-components';

export interface IScProviderHero {
	color?: string;
}

export const ScProviderCyclingHeroContainer = styled(motion.div)`
	height: 100%;
	@media (max-height: 600px) {
		height: 100%;
	}
	@media (min-width: 380px) and (min-height: 750px) {
		height: calc(100svh - 75px);
	}
	@media (min-width: 768px) and (min-height: 750px) {
		height: calc(100svh - 110px);
	}
	@media (min-width: 1220px) and (min-height: 700px) {
		height: calc(100vh - 140px);
	}
`;

export const ScProviderCyclingHeroWrapper = styled(motion.div)`
	display: grid;
	height: 100%;
	column-gap: 24px;
	row-gap: 8px;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
	min-height: max-content;
	@media (min-width: 768px) {
		grid-template-rows: 1fr;
		grid-template-columns: 1fr 1fr;
	}
`;

export const ScProviderHeroItem = styled(motion.div)<IScProviderHero>`
	padding: 45px 20px;
	box-sizing: border-box;
	background: ${({ theme, color }) => color || theme.colors.marmelade};
	justify-content: space-between;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;
	gap: 20px;
	width: 100%;
	min-height: max-content;
	img {
		max-height: 140px;
		max-width: 100%;
	}
	h3 {
		color: ${({ theme }) => theme.colors.darkText};
		text-align: center;
		margin: 0;
		font: ${({ theme }) => theme.fonts.heading.xss};
		@media (min-width: 360px) {
			font: ${({ theme }) => theme.fonts.heading.xs};
		}
		@media (min-width: 530px) {
			font: ${({ theme }) => theme.fonts.heading.small};
		}
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.heading.mediumLargeBold};
		}
		@media (min-width: 1920px) {
			font: ${({ theme }) => theme.fonts.heading.mediumXl};
		}
	}
	div {
		max-width: 540px;
		text-align: center;

		@media (min-width: 1220px) {
			font: ${({ theme }) => theme.fonts.paragraph.large};
		}

		@media (min-width: 1920px) {
			max-width: 600px;
			font: ${({ theme }) => theme.fonts.paragraph.xl};
		}
	}
	@media (min-width: 768px) {
		img {
			max-height: 270px;
		}
	}
	@media (max-height: 820px) {
		img {
			height: 40%;
		}
	}
	@media (min-width: 768px) {
		height: 100%;
		border-radius: 30px;
	}
	@media (min-width: 1220px) {
		padding: 65px 18px;
		gap: 32px;
		height: 100%;
	}
	@media (min-width: 1220px) and (min-height: 900px) {
		padding: 96px 18px;
	}
`;

export const ScProviderCyclingHeroButtons = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;

	button {
		padding: 11px 20px;
		font-size: 12px;
		line-height: normal;
	}

	@media (min-width: 768px) {
		button {
			height: fit-content;
			font: ${({ theme }) => theme.fonts.button.large};
			padding: 15px 30px;
		}
	}
	@media (min-width: 1220px) {
		gap: 24px;
		align-items: center;
	}
`;

export const ScBenefitsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 8px;

	@media (min-width: 768px) {
		margin-top: 20px;
	}
`;

export const ScBenefitsItemsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 8px;
	width: 100%;

	@media (min-width: 1220px) {
		gap: 20px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		margin-bottom: 20px;
		p,
		h6 {
			margin: 0 auto;
		}
		h6 {
			margin: 0 auto;
		}
	}
`;

export const ScCustomersHeroWrapper = styled(motion.section)`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	margin: 60px 0;

	img {
		width: 300px;
		height: auto;
	}

	@media (min-width: 768px) {
		img {
			width: 610px;
		}
	}

	@media (min-width: 1220px) {
		margin: 120px 0 120px 0;
		gap: 80px;

		img {
			width: 920px;
		}
	}
	@media (min-width: 1920px) {
		margin: 200px 0 140px 0;
		gap: 130px;

		img {
			width: 920px;
		}
	}
`;

export const ScCustomersHeroTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	max-width: 900px;

	@media (min-width: 1220px) {
		gap: 54px;
	}

	@media (min-width: 1920px) {
		max-width: 1000px;
	}
`;

export const ScCustomersHeroInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;

	button {
		padding: 10px 32px;
		height: 35px;
	}

	.text {
		font: ${({ theme }) => theme.fonts.button.small};
	}
	@media (min-width: 768px) {
		gap: 64px;

		button {
			padding: 17px 50px;
			height: fit-content;
		}

		.text {
			font: ${({ theme }) => theme.fonts.button.largeThin};
		}
	}
`;

export const ScFlexiVoucherWrapper = styled.div`
	background: ${({ theme }) => theme.colors.dough};
	border-radius: 10px;
	padding: 30px 16px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 35px;
	margin-bottom: 120px;
	img {
		width: 215px;
		height: auto;
		align-self: center;
	}
	h3 {
		font: ${({ theme }) => theme.fonts.heading.xss};
	}

	@media (min-width: 360px) {
		margin-bottom: 30px;
	}

	@media (min-width: 768px) {
		border-radius: 30px;
		padding: 60px;
		img {
			width: 300px;
			height: auto;
			margin: auto 0;
		}
		h3 {
			font: ${({ theme }) => theme.fonts.heading.card};
		}
	}

	@media (min-width: 1220px) {
		padding: 65px 35px 62px 58px;
		flex-direction: row-reverse;
		justify-content: space-between;
		gap: 150px;
		img {
			width: 360px;
			height: auto;
			flex-shrink: 0;
		}
		h3 {
			font-size: 50px;
			line-height: 56px;
		}
		.text {
			max-width: 920px;
		}
	}

	@media (min-width: 1440px) {
		padding-right: 60px;
		margin-bottom: 120px;
	}

	@media (min-width: 1920px) {
		img {
			margin: 0;
		}
		gap: 300px;
	}
`;

export const ScTitleWrapper = styled.div`
	@media (min-width: 1440px) {
		h6 {
			font: ${({ theme }) => theme.fonts.heading.mediumLarge} !important;
		}
	}
`;
