import React from 'react';
import { useTheme } from 'styled-components';

import { isNotSSR } from '@utils';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInUp } from '../../animations/variants';
import bikeLogo from '../../assets/images/bike-logo.png';
import handshake from '../../assets/images/promise-4.png';
import { ScContainer } from '../../components/container/styled';
import { CustomButton } from '../../components/custom-button';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTop } from '../../components/Top/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { useMatchMedia } from '../../hooks/use-match-media';

import {
	ScProviderCyclingHeroButtons,
	ScProviderCyclingHeroContainer,
	ScProviderCyclingHeroWrapper,
	ScProviderHeroItem,
} from './styled';

export const ProviderCyclingHero = () => {
	const theme = useTheme();
	const { ref, controls } = useContentAnimation();
	const width = useMatchMedia(`(max-width: 767px)`);
	const height = useMatchMedia(`(max-height: 749px)`);
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);

	return (
		<ScTop disableHeight={width || height}>
			<ScProviderCyclingHeroContainer
				ref={ref}
				initial="hidden"
				animate={controls}
				variants={animationContainer}
			>
				<ScContainer>
					<ScProviderCyclingHeroWrapper>
						<ScProviderHeroItem variants={fadeInUp} color={theme.colors.marmelade}>
							<img src={bikeLogo} alt="bike" />
							<h3>New here?</h3>
							<ScParagraph color={theme.colors.darkText} centered={!isDesktop}>
								Sign up today to become part of our retailer partner network and benefit from our
								low 3% commission.
							</ScParagraph>
							<MotionDiv variants={fadeInUp} className="buttons">
								<ScProviderCyclingHeroButtons>
									<CustomButton
										color={theme.colors.darkText}
										background={theme.colors.wheat}
										onClick={() => {
											if (isNotSSR) {
												window.location.href = `${process.env.GATSBY_BIKE_WEBSITE}/portal/retailer_portal/register`;
											}
										}}
									>
										Sign up
									</CustomButton>
								</ScProviderCyclingHeroButtons>
							</MotionDiv>
						</ScProviderHeroItem>
						<ScProviderHeroItem variants={fadeInUp} color={theme.colors.peach}>
							<img src={handshake} alt="handshake" />
							<h3>Already a partner?</h3>
							<ScParagraph color={theme.colors.darkText} centered={!isDesktop}>
								Click the link below to redeem a voucher or scroll down to find out how to let
								customers know about Gogeta Bike.
							</ScParagraph>
							<MotionDiv variants={fadeInUp} className="buttons">
								<ScProviderCyclingHeroButtons>
									<CustomButton
										color={theme.colors.darkText}
										background={theme.colors.wheat}
										onClick={() => {
											if (isNotSSR) {
												window.location.href = `${process.env.GATSBY_BIKE_WEBSITE}/portal/retailer_portal/verify-retailer-and-voucher?action=REDEEM_VOUCHER`;
											}
										}}
									>
										Redeem a voucher
									</CustomButton>
								</ScProviderCyclingHeroButtons>
							</MotionDiv>
						</ScProviderHeroItem>
					</ScProviderCyclingHeroWrapper>
				</ScContainer>
			</ScProviderCyclingHeroContainer>
		</ScTop>
	);
};
